<template>
    <div class="company-info-box">
        <div class="foot-content">
            <div class="company-name">
                <img src="@/assets/images/logo.png" alt="">
                <span>五更科技</span>
            </div>
            <div class="company-describe">
                <div class="left-describe">
                    <div class="left-describe">
                        感谢您的关注、陪伴和信任，我们会以诚心诚信和热情服务于每一位客户。如果想要更深入了解我们的产品信息，可通过以下方式联系我们，我们会及时响应，为您提供贴心服务。
                    </div>
                    <div class="left-email">邮箱：3338790967@QQ.COM</div>
                    <div>地址：湖南省长沙市岳麓区中电软件园二期B2栋606号</div>
                </div>
                <div class="right-describe">
                    <div class="code-box">
                        <img src="@/assets/images/customer-service-code.png" alt="">
                        <div class="text">企业微信客服</div>
                    </div>
                    <div class="code-box">
                        <img src="@/assets/images/dub-official-accounts.png" alt="">
                        <div class="text">音小二公众号</div>
                    </div>
                    <div class="code-box">
                        <img src="@/assets/images/create-official-accounts.png" alt="">
                        <div class="text">创作派公众号</div>
                    </div>
                </div>
            </div>
            <div class="line"></div>
            <div class="foot-info">
                <div>长沙五更网络科技有限公司</div>
                <button @click="toRecordWebsite">湘ICP备2023024009号</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        toRecordWebsite() {
            window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank")
        },
    }
}
</script>

<style lang="scss" scoped>
.company-info-box {
    width: 100%;
    height: 492px;
    // padding: 0 260px;
    box-sizing: border-box;
    background: #333333;

    .foot-content {
        width: 1400px;
        margin: 0 auto;
    }

    .company-name {
        padding-top: 60px;

        img {
            width: 60px;
            height: 60px;
        }

        span {
            font-size: 32px;
            font-weight: 900;
            color: #FFFFFF;
            vertical-align: 18px;
            margin-left: 20px;
        }
    }

    .company-describe {
        display: flex;
        margin-top: 50px;

        .left-describe {
            width: 680px;
            margin-right: 220px;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;

            .left-describe {
                margin-bottom: 30px;
            }

            .left-email {
                margin-bottom: 20px;
            }
        }

        .right-describe {
            display: flex;

            .code-box {
                margin-right: 40px;

                img {
                    width: 140px;
                    height: 140px;

                }

                .text {
                    margin-top: 14px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }

        }
    }

    .line {
        width: 100%;
        margin: 50px 0 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .foot-info {
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.3);

        button {
            color: rgba(255, 255, 255, 0.3);
        }
    }
}
</style>