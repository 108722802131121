<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  data() {
    return {
      screenWidth: null,
      path: null
    }
  },
  components: { Header, Footer },
  created() {
    var _hmt = _hmt || [];
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?38d7c4b2f043d61cd1bf5aea16018f9c";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);

  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.documentElement.clientWidth
      })()
    }
  },
  methods: {

  },
  watch: {
    $route(to, from) {
      this.path = to.path
    }
  },

}
</script>

<style lang="scss">
@import '@/assets/reset.css';

div[data-w-e-type="video"] {
  text-align: center;
}
</style>
