<template>
  <div class="header-box">
    <div class="header-content">
      <button class="company-info" @click="toHomePage">
        <img src="@/assets/images/logo.png" alt="" class="logo-icon">
        <!-- <div class="company-name">五更科技</div> -->
      </button>
      <div class="toolbar-box">
        <button v-for="(item, index) in toolbarArr" :key="index" class="item-tool" @click="chooseToolbar(index)"
          :class="currentToolbar == index ? 'active-tool' : ''">{{ item }}</button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      toolbarArr: ['首页', '配音工具', '视频工具', '关于我们', '平台通知'],
      currentToolbar: 0
    }
  },
  created() {
  },
  methods: {
    toHomePage() {
      this.$router.push('/')
      this.currentToolbar = 0
    },
    chooseToolbar(index) {
      window.scroll(0, 0);
      this.currentToolbar = index
      if (index == 0) {
        this.$router.push('/')
      } else if (index == 1) {
        this.$router.push('/dubTool')
      } else if (index == 2) {
        this.$router.push('/videoTool')
      } else if (index == 3) {
        this.$router.push('/company')
      } else if (index == 4) {
        this.$router.push('/inform')
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == '/') {
        this.currentToolbar = 0
      } else if (to.path == '/dubTool') {
        this.currentToolbar = 1
      } else if (to.path == '/videoTool') {
        this.currentToolbar = 2
      } else if (to.path == '/company') {
        this.currentToolbar = 3
      } else if (to.path == '/inform') {
        this.currentToolbar = 4
      }

    },
  }
}
</script>

<style scoped lang="scss">
.header-box {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-image: url('@/assets/images/head-bg.png');
  backdrop-filter: blur(20px);

  .header-content {
    width: 1400px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .company-info {

      display: flex;
      align-items: center;
      margin-right: 200px;

      .logo-icon {
        width: 48px;
        height: 48px;
        margin-right: 14px;
      }

      .company-name {
        font-size: 24px;
        font-weight: 900;
      }
    }

    .toolbar-box {
      display: flex;

      .item-tool {
        font-weight: 700;
        font-size: 22px;
        margin-right: 110px;

        &:last-child {
          margin-right: 0;
        }

      }

      .active-tool {
        color: #3662EC;
      }
    }
  }


}
</style>
