import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
    meta: {
      content: {
        keywords: '在线配音，文字转语音，文字转语音在线生成，配音生成',
      }
    }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/article.vue')
  },
  {
    path: '/dubTool',
    name: 'dubTool',
    component: () => import(/* webpackChunkName: "about" */ '../views/dubTool/index.vue'),
    meta: {
      content: {
        keywords: '在线配音，文字转语音，文字转语音在线生成，配音生成',
      }
    }
  },
  {
    path: '/videoTool',
    name: 'videoTool',
    component: () => import(/* webpackChunkName: "about" */ '../views/videoTool/index.vue'),
    meta: {
      content: {
        keywords: '视频去重，文案提取，去水印，文案伪原创',
      }
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "about" */ '../views/company/index.vue'),
    meta: {
      content: {
        keywords: '创作软件，ai生成，配音制作，配音网',
      }
    }
  },
  {
    path: '/inform',
    name: 'inform',
    component: () => import(/* webpackChunkName: "about" */ '../views/inform/index.vue')
  },
]



// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  base: '/',
  mode: 'history',
  routes
})

export default router
